import React from "react";
import "./App.css";
import { Header } from "./Header";
import { Quote } from "./Quote";
import { ThreeHero } from "./ThreeHero";

function App() {
  return (
    <div className="ssui-wrapper">
      {<Header />}
      {/*Quote()*/}
      {/*ThreeHero*/}
    </div>
  );
}

export default App;
